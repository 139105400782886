<script lang="ts">
  import puzzle_words from './puzzle_words';

  let filteredWords = [];

  function filter(template, exceptionstring) {
    filteredWords = puzzle_words;

    let containChars = [];
    let positionalChars = [];
    let exceptions = [...exceptionstring];

    [...template].forEach((char, index) => {
      if (char == '*') {
      } else if (char == char.toUpperCase()) {
        positionalChars.push({
          char: char,
          index: index,
        });
      } else if (char == char.toLowerCase()) {
        containChars.push({
          char: char,
          index: index,
        });
      }
    });

    if (exceptions.length > 0) {
      filteredWords = puzzle_words.filter((word) =>
        exceptions.every((char) => !word.includes(char))
      );
    }

    if (containChars.length > 0) {
      filteredWords = filteredWords.filter((word) =>
        containChars.every((char) => word.includes(char.char))
      );
    }

    containChars.forEach((value) => {
      filteredWords = filteredWords.filter(
        (word) => word.charAt(value.index) != value.char.toLowerCase()
      );
    });

    positionalChars.forEach((value) => {
      filteredWords = filteredWords.filter(
        (word) => word.charAt(value.index) == value.char.toLowerCase()
      );
    });
  }

  let templateInput = '';
  let templateExceptions = '';

  $: filter(templateInput, templateExceptions);
</script>

<main>
  <div>
    <h1>Woordle helper</h1>
    <p>
      Template: <br />
      <strong>Lowercase</strong>: letter zit in het woord maar niet op de goede
      plek<br />
      <strong>*</strong>: onbekende letter<br />
      <strong>Uppercase</strong>: letter zit in het woord op de goede plek<br />
    </p>
  </div>
  <div class="template">
    <input
      type="text"
      placeholder="template"
      bind:value={templateInput}
      maxlength="6"
    />
    <input
      type="text"
      placeholder="foute karakters"
      bind:value={templateExceptions}
    />
    <span>
      {filteredWords.sort().join(', ')}
    </span>
  </div>
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 50vw;
    margin: 0 auto;
  }

  .template {
    display: flex;
    flex-direction: column;
    place-items: center;
  }

  .template > input {
    max-width: fit-content;
  }

  .template > span {
    font-family: monospace;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }
</style>
